import {
	Radio,
	Form,
	FormInstance,
	Input,
	message,
	Select,
	Space,
	Button,
	Checkbox,
} from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import SectionContent from '../../components/SectionContent';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import { getErrorMessage } from '../../helpers/errorHandler';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { generateFormRules } from '../../helpers/formRules';
import { BannerProps, initialBanner } from '../../types/banner.type';
import ModalSelectArticle from '../../components/Banner/ModalSelectArticle';
import ModalSelectVoucher from '../../components/Banner/ModalSelectVoucher';
import ModalSelectMedicalEvent from '../../components/Banner/ModalSelectMedicalEvent';
import SelectedArticle from '../../components/Banner/SelectedArticle';
import SelectedVoucher from '../../components/Banner/SelectedVoucher';
import SelectedMedicalUpdate from '../../components/Banner/SelectedMedicalEvent';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import FormItem from 'antd/es/form/FormItem';

interface IParams {
	bannerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: BannerProps;
}

const { Option } = Select;

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const PromoDealsEdit: React.FC = () => {
	const history = useHistory();
	const { bannerId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<BannerProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [isShowModalArticle, setIsShowModalArticle] =
		React.useState<boolean>(false);
	const [isShowModalMedicalEvent, setIsShowModalMedicalEvent] =
		React.useState<boolean>(false);
	const [isShowModalVoucher, setIsShowModalVoucher] =
		React.useState<boolean>(false);
	const [selectedContent, setSelectedContent] = React.useState<any>();

	const [banner, setBanner] = React.useState<BannerProps>(initialBanner);

	const [image, setImage] = React.useState<File | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();

	const [groups, setGroups] = React.useState<Array<String>>([
		'VOUCHER',
		'ARTICLE',
		'MEDICAL_EVENT',
		'REFER_A_FRIEND',
		'URL',
		'ORDER_CONSULTATION',
		'ORDER_MEDICAL_SERVICE',
		'OWN_CONTENT',
	]);

	const handleRelatedContentType = (bannerType: string) => {
		switch (bannerType) {
			case 'ORDER_CONSULTATION':
				return 'ORDER_CONSULTATION';
			case 'ORDER_MEDICAL_SERVICE':
				return 'ORDER_MEDICAL_SERVICE';
			case 'REFER_A_FRIEND':
				return 'REFER_A_FRIEND';
			default:
				return '';
		}
	};

	const createBanner = async (
		props: Omit<BannerProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			const formData = new FormData();

			if (image) {
				formData.append('imageUrl', image);
			}

			setIsLoadingAction(true);
			const res: any = await httpRequest.post('/banners', {
				...props,
				bannerType: banner.bannerType,
				title: banner.title,
				content: banner.content,
				relatedContentType: handleRelatedContentType(banner.bannerType),
				relatedContentId: banner.relatedContentId,
				relatedContentUrl: banner.relatedContentUrl,
				isPublished: banner.isPublished,
			});

			if (res && res.data) {
				console.info('res.data', res.data);
				await uploadNewImage(res.data.payload.bannerId);
			}

			message.success('Success create ' + props.title);
			history.push('/promodeals');
			setIsLoadingAction(false);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateBanner = async (
		props: Omit<BannerProps, 'createdAt' | 'updatedAt'>,
	) => {
		try {
			setIsLoadingAction(true);

			const res: any = {
				...props,
				bannerType: banner.bannerType,
				title: banner.title,
				content: banner.content,
				relatedContentType: handleRelatedContentType(banner.bannerType),
				relatedContentId: banner.relatedContentId,
				relatedContentUrl: banner.relatedContentUrl,
				isPublished: banner.isPublished,
			};

			if (bannerId) {
				await Promise.all([
					httpRequest.patch('/banners/' + bannerId, res),
					uploadNewImage(bannerId),
				]);
			}

			message.success('Success update ' + props.title);
			history.push('/promodeals');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const uploadNewImage = async (bannerId: string) => {
		try {
			console.log(image);
			if (image) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('image', image);

				await httpRequest.put(
					'/banners/' + 'upload-image/' + bannerId,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				setIsLoadingAction(false);
			}
			console.log('success image');
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleEmptyRelatedContent = () => {
		switch (banner.bannerType) {
			case 'ARTICLE':
			case 'VOUCHER':
			case 'MEDICAL_EVENT':
				return banner.relatedContentId ? false : true;
			default:
				return false;
		}
	};

	const handleSubmit = async (
		values: Omit<BannerProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		console.info('values', values);

		if (handleEmptyRelatedContent()) {
			message.error('Cannot submit with empty related content');
			return;
		}

		if (bannerId) {
			updateBanner(values);
		} else {
			createBanner(values);
		}
	};

	const handleSelectedContent = async (
		bannerType: string,
		contentId: string,
	) => {
		switch (bannerType) {
			case 'ARTICLE':
				const article = await httpRequest.get<any>('/articles/' + contentId);
				setSelectedContent(article.data.payload);
				break;
			case 'VOUCHER':
				const voucher = await httpRequest.get<any>('/vouchers/' + contentId);
				setSelectedContent(voucher.data.payload);
				break;
			case 'MEDICAL_EVENT':
				const event = await httpRequest.get<any>('/events/' + contentId);
				setSelectedContent(event.data.payload);
				break;
		}
	};

	React.useEffect(() => {
		if (bannerId) {
			const fetchBanner = async () => {
				try {
					setIsLoading(true);
					const res = await httpRequest.get<ResponseProps>(
						'/banners/' + bannerId,
					);
					const initBanner = res.data.payload;
					setBanner(initBanner);
					setImageUrl(initBanner.bannerImageUrl);

					if (initBanner.relatedContentId) {
						await handleSelectedContent(
							initBanner.bannerType,
							initBanner.relatedContentId,
						);
					}
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchBanner();
		}
	}, [bannerId]);

	const handleChangeGroup = (value: any) => {
		setSelectedContent('');
		setBanner((oldVal) => {
			return {
				...oldVal,
				bannerType: value,
			};
		});
	};

	const renderSelectedContent = (content: any) => {
		switch (banner.bannerType) {
			case 'ARTICLE':
				return <SelectedArticle content={content} />;
			case 'VOUCHER':
				return <SelectedVoucher content={content} />;
			case 'MEDICAL_EVENT':
				return <SelectedMedicalUpdate content={content} />;
		}
	};

	const renderRelatedBannerType = () => {
		switch (banner.bannerType) {
			case 'ARTICLE':
			case 'VOUCHER':
			case 'MEDICAL_EVENT':
				return (
					<Form.Item
						label="Content ID"
						style={{ flexDirection: 'column' }}
						name="relatedContentId"
					>
						{selectedContent && renderSelectedContent(selectedContent)}
						<Button
							style={{
								color: '#D81F64',
								borderColor: '#D81F64',
								width: '100%',
							}}
							onClick={() => {
								switch (banner.bannerType) {
									case 'ARTICLE':
										setIsShowModalArticle(true);
										break;
									case 'VOUCHER':
										setIsShowModalVoucher(true);
										break;
									case 'MEDICAL_EVENT':
										setIsShowModalMedicalEvent(true);
										break;
								}
							}}
						>
							{`Choose ${banner.bannerType.toLowerCase().replace(/_/g, ' ')}`}
						</Button>
					</Form.Item>
				);
			case 'URL':
				return (
					<Form.Item
						label="URL"
						name="relatedContentUrl"
						rules={generateFormRules('Url', ['required'])}
					>
						<Input
							value={banner.relatedContentUrl}
							placeholder="Input content url"
							onChange={function (e) {
								setBanner({
									...banner,
									relatedContentUrl: e.target.value,
								});
							}}
						/>
					</Form.Item>
				);
			case 'OWN_CONTENT':
				return (
					<Form.Item
						label="Content"
						name="content"
						rules={generateFormRules('Content', ['required'])}
					>
						<ReactQuill
							theme="snow"
							// value={banner.contentText}
							placeholder="Input content"
							onChange={(val) =>
								setBanner({
									...banner,
									content: val,
								})
							}
							value={banner.content}
							modules={quillModules}
							formats={quillFormats}
						/>
					</Form.Item>
				);
			default:
				break;
		}
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Promo Deals"
				subtitle="Manage your promo deals data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="bannerForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={bannerId ? banner : initialBanner}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Information"
						helpers={[
							{
								title: 'Information',
								content: (
									<div
										style={{
											maxWidth: '80%',
											wordWrap: 'break-word',
											overflow: 'hidden',
											whiteSpace: 'normal',
										}}
									>
										Accepted image type are jpg, jpeg and png. Max file size is
										1 Mb.
									</div>
								),
							},
						]}
					>
						<h4 style={{ color: '#556575' }}>Promo deals image</h4>
						<Form.Item
							name="image"
							rules={[
								{
									required: true,
									message: 'Image is Required',
								},
							]}
						>
							<FormUploadImage
								mode={'single-large'}
								maxSizeInMB={1}
								imageUrl={imageUrl || ''}
								onChange={(file) => {
									setImage(file);
								}}
								onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
							/>
						</Form.Item>

						<Form.Item
							label="Title"
							name="title"
							rules={generateFormRules('Title', ['required'])}
						>
							<Input
								value={banner.title}
								placeholder="Input title"
								onChange={function (e) {
									setBanner({
										...banner,
										title: e.target.value,
									});
								}}
							/>
						</Form.Item>

						<Form.Item
							label="Promo Deals Type"
							name="bannerType"
							rules={generateFormRules('Banner Type', ['required'])}
						>
							<Select
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={handleChangeGroup}
								key={'groupSelect'}
								value={banner.bannerType ? banner.bannerType : ''}
							>
								{groups.map((group, index) => {
									return (
										<Option key={`group${index}`} value={String(group)}>
											{group.replace(/_/g, ' ')}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						{renderRelatedBannerType()}

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Target"
							name="target"
							rules={generateFormRules('Target', ['required'])}
						>
							<CustomCheckboxGroup
								options={['DOCTOR', 'PATIENT']}
								onChange={(list: CheckboxValueType[]) => {
									const selectedTarget = list.map((item: any) => item);
									setBanner((oldVal) => ({
										...oldVal,
										target: selectedTarget,
									}));
								}}
								value={banner.target}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group value={banner.isPublished}>
								<CustomRadio
									onChange={(e) => {
										setBanner({
											...banner,
											isPublished: e.target.checked,
										});
									}}
									value={true}
								>
									Published
								</CustomRadio>
								<CustomRadio
									onChange={(e) => {
										setBanner({
											...banner,
											isPublished: e.target.checked,
										});
									}}
									value={false}
								>
									Unpublished
								</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
				<ModalSelectArticle
					setIsVisible={setIsShowModalArticle}
					isVisible={isShowModalArticle}
					onSelect={(article) => {
						setBanner({
							...banner,
							relatedContentId: article.articleId,
						});
						setSelectedContent(article);
					}}
				/>
				<ModalSelectVoucher
					setIsVisible={setIsShowModalVoucher}
					isVisible={isShowModalVoucher}
					onSelect={(voucher) => {
						setSelectedContent(voucher);
						setBanner({
							...banner,
							relatedContentId: voucher.voucherId,
						});
					}}
				/>
				<ModalSelectMedicalEvent
					setIsVisible={setIsShowModalMedicalEvent}
					isVisible={isShowModalMedicalEvent}
					onSelect={(event) => {
						setBanner({
							...banner,
							relatedContentId: event.eventId,
						});

						setSelectedContent(event);
					}}
				/>
			</AppCard>
		</div>
	);
};

export default PromoDealsEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}

	.ant-checkbox-group-item {
		margin-right: 5.6rem;
	}
`;
