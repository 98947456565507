import {
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Checkbox,
	Typography,
} from 'antd';
import React from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateFormRules } from '../../../helpers/formRules';
import { generateQueryString } from '../../../helpers/generateQueryString';
import { UploadFile } from 'antd/lib/upload/interface';
import { getErrorMessage } from '../../../helpers/errorHandler';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import { ArticleProps, initialArticle } from '../../../types/article.type';
import { FetchAllTopicResponse, TopicProps } from '../../../types/topic.type';
import FormUploadImage from '../../../components/FormUpload/FormUploadImage';
import styled from 'styled-components';
import { UploadInput } from '@qlibs/react-components';

interface IParams {
	articleId: string;
}

interface ILocation {
	articleId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ArticleProps, 'createdAt' | 'updatedAt'>;
}

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const { Option } = Select;

const READERS_OPTION = ['DOCTOR', 'PATIENT'];

const ArticleEdit: React.FC = () => {
	const { Text } = Typography;
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const location = useLocation<ILocation>();
	const { articleId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<ArticleProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [article, setArticle] = React.useState<ArticleProps>(initialArticle);

	const [topics, setTopics] = React.useState<Array<TopicProps>>([]);
	const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>([]);
	const [headline, setHeadline] = React.useState<string>('');
	const [image, setImage] = React.useState<File | any | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();
	const [showWarning, setShowWarning] = React.useState<boolean>(false);

	const createArticle = async (
		props: Omit<
			ArticleProps,
			'createdAt' | 'updatedAt' | 'articleId' | 'statusLoading'
		>,
	) => {
		try {
			setIsLoadingAction(true);
			console.log(props);

			const formData = new FormData();
			formData.append('title', props.title);
			formData.append('contentText', props.contentText);
			formData.append(
				'rssDetail[fullArticleUrl]',
				props.rssDetail.fullArticleUrl,
			);
			formData.append('rssDetail[rssUrl]', props.rssDetail.fullArticleUrl);
			formData.append('isPublished', props.isPublished ? '1' : '0');
			formData.append('topics', props.topics.toString());
			formData.append('targetReaders', props.targetReaders.toString());

			if (image) {
				formData.append('image', image);
			}

			// await httpRequest.post(
			// 	'/articles',
			// 	{
			// 		image: image,
			// 		title: props.title,
			// 		contentText: props.contentText,
			// 		isPublished: props.isPublished,
			// 		topics: topicIds,
			// 		targetReaders: article.targetReaders,
			// 	},
			// 	{
			// 		headers: {
			// 			'Content-Type': 'multipart/form-data',
			// 		},
			// 	},
			// );

			await httpRequest.post('/articles', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			message.success('Success create ' + props.title);
			history.push('/article');
			setIsLoadingAction(false);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateArticle = async (
		props: Omit<ArticleProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			const formData = new FormData();
			formData.append('title', props.title);
			formData.append('contentText', props.contentText);
			formData.append(
				'rssDetail[fullArticleUrl]',
				props.rssDetail.fullArticleUrl,
			);
			formData.append('rssDetail[rssUrl]', props.rssDetail.fullArticleUrl);
			formData.append('isPublished', props.isPublished ? '1' : '0');
			formData.append('topics', props.topics.toString());
			formData.append('targetReaders', props.targetReaders.toString());

			if (image) {
				formData.append('image', image);
			}

			// await httpRequest.patch(
			// 	'/articles/' + articleId,
			// 	{
			// 		image: image,
			// 		title: props.title,
			// 		contentText: props.contentText,
			// 		isPublished: props.isPublished,
			// 		topics: topicIds,
			// 		targetReaders: article.targetReaders,
			// 	},
			// 	{
			// 		headers: {
			// 			'Content-Type': 'multipart/form-data',
			// 		},
			// 	},
			// );

			await httpRequest.patch('/articles/' + articleId, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			message.success('Success update ' + props.title + ' data');
			history.push('/article');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (
		values: Omit<ArticleProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		if (articleId) {
			updateArticle(values);
		} else {
			createArticle(values);
		}
	};

	const handleChangeTopic = (value: any) => {
		const selectedTopics = value.map((selectedArticleId: string) => {
			const foundTopic = topics.find(
				(topic) => topic.topicId === selectedArticleId,
			);
			return foundTopic;
		});

		setArticle((oldVal) => {
			return {
				...oldVal,
				topics: selectedTopics,
			};
		});
	};

	const handleChangeReaders = (list: CheckboxValueType[]) => {
		setCheckedList(list);
		const selectedReaders = list.map((readers: any) => {
			return readers;
		});

		setArticle((oldVal) => {
			return {
				...oldVal,
				targetReaders: selectedReaders,
			};
		});
	};

	React.useEffect(() => {
		if (articleId) {
			const fetchArticleDetail = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/articles/' + articleId,
					);
					setArticle(res.data.payload);
					if (res.data.payload.images && res.data.payload.images.length > 0) {
						setImageUrl(res.data.payload.images[0]);
					}

					const bcDetails = [
						{
							field: 'articleId',
							value: articleId,
							label: res.data.payload.title,
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchArticleDetail();
		}
		console.log(article);

		const getTopicsData = async () => {
			const topicsRes = await httpRequest.get<FetchAllTopicResponse>(
				`/topics${generateQueryString({
					isPublished: 'active',
				})}`,
			);
			setTopics(topicsRes.data.payload.results);
		};

		getTopicsData();
	}, [articleId, location]);

	const handleHeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setHeadline(value);
		setShowWarning(value.length > 100);
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title={(articleId ? 'Edit' : 'Add') + ' Article'}
				subtitle="Manage your article data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="articleForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={
						articleId
							? {
									...article,
									topics: article.topics
										? article.topics.map((item) => item.topicId || '')
										: [],
							  }
							: initialArticle
					}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Article Category"
						helpers={[
							{
								title: 'Information',
								content: (
									<div
										style={{
											maxWidth: '80%',
											wordWrap: 'break-word',
											overflow: 'hidden',
											whiteSpace: 'normal',
										}}
									>
										Accepted image type are jpg, jpeg and png. Max file size is
										1 Mb.
									</div>
								),
							},
						]}
					>
						{/* <h4>Article Cover</h4>
						<FormUploadImage
							mode={'single-large'}
							maxSizeInMB={1}
							imageUrl={imageUrl || ''}
							onChange={(file) => {
								setImage(file);
							}}
							onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
							recommendedResolution="1000x50px"
						/> */}
						<Form.Item
							name="file"
							label="Upload Banner"
							rules={generateFormRules('Banner', ['required'])}
						>
							<UploadInput
								maxFileSize={1}
								onChange={(file) => {
									setImage(file);
								}}
								allowedExtension={['jpg', 'png']}
								enableAutoResizeForImage={true}
								configAutoResizeForImage={{
									maxFileWidth: 350,
								}}
							/>
						</Form.Item>
						<Form.Item
							label="Headline"
							name="title"
							rules={generateFormRules('Headline', ['required'])}
						>
							<Input onChange={handleHeadlineChange} value={headline} />
						</Form.Item>
						{showWarning && (
							<Text style={{ color: 'orange', fontSize: 13 }}>
								Headline should not exceed 100 characters!
							</Text>
						)}

						<Form.Item
							label="Article Content"
							name="contentText"
							rules={generateFormRules('Article Content', ['required'])}
						>
							<ReactQuill
								theme="snow"
								value={article.contentText}
								onChange={(val) =>
									setArticle({
										...article,
										contentText: val,
									})
								}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Form.Item>

						<Form.Item label="Reference" name={['rssDetail', 'fullArticleUrl']}>
							<Input
								value={article.rssDetail?.fullArticleUrl}
								onChange={(e) =>
									setArticle({
										...article,
										rssDetail: {
											...article.rssDetail,
											fullArticleUrl: e.target.value,
										},
									})
								}
							/>
						</Form.Item>

						<Form.Item
							label="Article Tag"
							name="topics"
							rules={generateFormRules('Article Tag', ['required'])}
						>
							<Select
								mode="multiple"
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={handleChangeTopic}
								value={
									article.topics
										? article.topics.map((item) => item.topicId || '')
										: []
								}
							>
								{topics.map((topic, index) => {
									return (
										<Option key={`topic${index}`} value={String(topic.topicId)}>
											{topic.topicName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Publish For"
							name="targetReaders"
							initialValue={article.targetReaders}
							rules={generateFormRules('Publish For', ['required'])}
						>
							<CustomCheckboxGroup
								options={READERS_OPTION}
								onChange={handleChangeReaders}
								value={checkedList}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group value={article.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default ArticleEdit;

const CustomCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: red;
		background-color: red;
	}

	.ant-checkbox-group-item {
		margin-right: 5.6rem;
	}
`;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
`;
