import { DoctorProps } from './doctor.type';
import { DoctorSecretaryProps } from './doctorSecretary.type';
import { PatientProps } from './patient.type';
import { PatientInRelationProps } from './patientInRelation.type';

export enum EUserType {
	customer = 'customer',
	admin = 'admin',
}

export type EAdminStatus = 'active' | 'inactive';

export interface IUser {
	userId?: string;
	name?: string;
	email?: string;
	gender?: 'male' | 'female';
	phone?: string;
	birthdate?: string;
	province?: string;
	city?: string;
	userType?: EUserType;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	nickName?: string;
	profilePic?: string;
	status?: EAdminStatus;

	isEmailVerified?: boolean;
	isPhoneVerified?: boolean;

	image?: File;

	doctor?: DoctorProps;
	patient?: PatientProps;
	secretary?: DoctorSecretaryProps[];
	patientInRelations?: PatientInRelationProps[];

	updatedAt?: Date | string;
	createdAt?: Date | string;
}

export const initialUser: IUser = {
	userId: '',
	name: '',
	email: '',
	phone: '',
	userType: EUserType.customer,
	status: 'active',
};

export type IChangePassword = {
	oldPassword: '';
	newPassword: '';
	retypePassword: '';
};
