import { message } from 'antd';

export function generateFormRules(
	formName: string,
	rules: Array<
		| 'required'
		| 'email'
		| 'phoneNumber'
		| 'letter-and-space'
		| 'password'
		| 'url'
	> = [],
): Array<{
	required?: boolean;
	message: string;
	pattern?: any;
}> {
	const formRules = [];
	if (rules.includes('required')) {
		formRules.push({
			required: true,
			message: `${formName} is required.`,
		});
	}
	if (rules.includes('email')) {
		formRules.push({
			pattern:
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: `${formName} format is invalid.`,
		});
	}
	if (rules.includes('phoneNumber')) {
		formRules.push({
			pattern: /^[0-9+ -]+$/,
			message: `${formName} can only include numbers, dash, plus and spacing.`,
		});

		formRules.push({
			max: 13,
			message: `${formName} cannot exceed 13 characters.`,
		});
		formRules.push({
			min: 10,
			message: `Minimum ${formName} is 10 characters.`,
		});
	}
	if (rules.includes('letter-and-space')) {
		formRules.push({
			pattern: /^[a-zA-Z ']+$/,
			message: `${formName} can only include letters.`,
		});
	}
	if (rules.includes('password')) {
		formRules.push({
			pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/,

			message: `${formName} minimum 8 characters (letter, number, and capital Letter)`,
		});
	}
	if (rules.includes('url')) {
		formRules.push({
			pattern:
				/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
			message: `${formName} must be a valid URL`,
		});
	}

	return formRules;
}
