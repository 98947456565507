import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from '../screens/auth/Login';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Profile from '../screens/profile';
import EditProfile from '../screens/profile/Edit';
import ForgotPassword from '../screens/auth/ForgotPassword';
import ResetPassword from '../screens/auth/ResetPassword';
import ChangeMyPassword from '../screens/profile/ChangeMyPassword';
import Customer from '../screens/customers';
import CustomerEdit from '../screens/customers/Edit';
import CustomerDetail from '../screens/customers/Detail';
import Dashboard from '../screens/dashboard';
import TagEdit from '../screens/tag/Edit';
import TagDetail from '../screens/tag/Detail';
import Tag from '../screens/tag';
import ChangePasswordAdmin from '../screens/admin/ChangePassword';
import Topic from '../screens/contentArticle/topic';
import TopicDetail from '../screens/contentArticle/topic/Detail';
import TopicEdit from '../screens/contentArticle/topic/Edit';
import Article from '../screens/contentArticle/article';
import ArticleDetail from '../screens/contentArticle/article/Detail';
import ArticleEdit from '../screens/contentArticle/article/Edit';
import CategoryEdit from '../screens/category/Edit';
import CategoryDetail from '../screens/category/Detail';
import Category from '../screens/category';
import ProductEdit from '../screens/product/Edit';
import ProductDetail from '../screens/product/Detail';
import Product from '../screens/product';
import EventTagEdit from '../screens/eventTag/Edit';
import EventTagDetail from '../screens/eventTag/Detail';
import EventTag from '../screens/eventTag';
import EventEdit from '../screens/event/Edit';
import EventDetail from '../screens/event/Detail';
import Event from '../screens/event';
import Partner from '../screens/partner';
import PartnerEdit from '../screens/partner/Edit';
import PartnerDetail from '../screens/partner/Detail';
import DoctorDetail from '../screens/doctor/Detail';
import Doctor from '../screens/doctor';
import MedicineCategoryEdit from '../screens/medicine-category/Edit';
import MedicineCategoryDetail from '../screens/medicine-category/Detail';
import MedicineCategory from '../screens/medicine-category';
import MedicineEdit from '../screens/medicine/Edit';
import MedicineDetail from '../screens/medicine/Detail';
import Medicine from '../screens/medicine';
import Specialization from '../screens/specialization';
import CustomerFeedback from '../screens/customer-feedback';
import IndividualEdit from '../screens/individualService/Edit';
import IndividualDetail from '../screens/individualService/Detail';
import IndividualService from '../screens/individualService';
import HealthServiceCategoryEdit from '../screens/medicalServiceCategory/Edit';
import HealthServiceCategory from '../screens/medicalServiceCategory';
import HealthServiceCategoryDetail from '../screens/medicalServiceCategory/Detail';
import DoctorEdit from '../screens/doctor/Edit';
import PackageService from '../screens/package-service';
import PackageEdit from '../screens/package-service/Edit';
import PackageDetail from '../screens/package-service/Detail';
import Admin from '../screens/admin';
import AdminEdit from '../screens/admin/Edit';
import AdminDetail from '../screens/admin/Detail';
import TransactionConsultation from '../screens/transaction/consultation';
import TransactionConsultationDetail from '../screens/transaction/consultation/Detail';
import TransactionPrescriptionLabRequset from '../screens/transaction/prescription-labrequest';
import FAQ from '../screens/faq';
import FAQEdit from '../screens/faq/Edit';
import FAQBalance from '../screens/faqBalance/index';
import FAQEditBalance from '../screens/faqBalance/Edit';
import FAQPoint from '../screens/faqPoint/index';
import FAQEditPoint from '../screens/faqPoint/Edit';
import PrivacyPolicyEdit from '../screens/privacy-policy/Edit';
import TnCEdit from '../screens/tnc/Edit';
import BankApproval from '../screens/bank-approval';
import ChatCustomerServiceEdit from '../screens/chatCustomerService/Edit';
import TransactionHealthService from '../screens/transaction/medical-service';
import TransactionHealthServiceDetail from '../screens/transaction/medical-service/Detail';
import RewardPointSettings from '../screens/rewardPointSettings/index';
import RewardPointSettingsDetails from '../screens/rewardPointSettings/Details';
import Fees from '../screens/fees';
import FeesDetail from '../screens/fees/Detail';
import Pharmacy from '../screens/pharmacy';
import DetailPharmacy from '../screens/pharmacy/DetailPharmacy';
import AddEditPharmacy from '../screens/pharmacy/AddEditPharmacy';
import DetailPharmacyMedicine from '../screens/pharmacy/DetailPharmacyMedicine';
import Withdrawal from '../screens/withdrawal';
import Redeem from '../screens/redeem';
import Voucher from '../screens/voucher';
import VoucherEdit from '../screens/voucher/Edit';
import VoucherDetail from '../screens/voucher/Detail';
import PromoDeals from '../screens/promodeals';
import PromoDealsEdit from '../screens/promodeals/Edit';
import PromoDealsDetail from '../screens/promodeals/Detail';
import MedicalCalculatorEdit from '../screens/medical-calculator/Edit';
import AppLayout from '../screens/layout/AppLayout';
import useAuth from '../hooks/useAuth';
import BankApprovalEdit from '../screens/bank-approval/Edit';
import Secretary from '../screens/secretary';
import SecretaryDetail from '../screens/secretary/Detail';
import PartnerServiceEdit from '../screens/partner/EditPartnerService';
import DetailMedicineHistory from '../screens/medicine/DetailMedicineHistory';
import Notification from '../screens/notification';
import Relative from '../screens/relative';
import PrescriptionLabDetail from '../screens/transaction/prescription-labrequest/Detail';
import ReferralInformationEdit from '../screens/referral-information/Edit';

const RootNavigator = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Router>
			{!isLoggedIn ? (
				<Switch>
					{/* Public Route */}
					<PublicRoute path="/reset-password/:sessionId" restricted={true}>
						<ResetPassword />
					</PublicRoute>
					<PublicRoute exact path="/" restricted={true}>
						<Login />
					</PublicRoute>
					<PublicRoute exact path="/login" restricted={true}>
						<Login />
					</PublicRoute>
					<PublicRoute exact path="/forgot-password" restricted={true}>
						<ForgotPassword />
					</PublicRoute>
				</Switch>
			) : (
				<AppLayout>
					<Switch>
						{/* Private Route */}
						<PrivateRoute exact path="/dashboard">
							<Dashboard />
						</PrivateRoute>

						<PrivateRoute exact path="/bank-approval">
							<BankApproval />
						</PrivateRoute>
						<PrivateRoute exact path="/bank-approval/:userBankAccountId/edit">
							<BankApprovalEdit />
						</PrivateRoute>

						<PrivateRoute
							exact
							path="/transaction-consultations/:transactionConsultationId"
						>
							<TransactionConsultationDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/transaction-consultations">
							<TransactionConsultation isTest={false} />
						</PrivateRoute>
						<PrivateRoute exact path="/transaction-consultations-test">
							<TransactionConsultation isTest={true} />
						</PrivateRoute>

						<PrivateRoute exact path="/transaction-prescription-labrequest">
							<TransactionPrescriptionLabRequset isTest={false} />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/transaction-prescription-labrequest-test"
						>
							<TransactionPrescriptionLabRequset isTest={true} />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/transaction-prescription-labrequest/:transactionId/:id"
						>
							<PrescriptionLabDetail />
						</PrivateRoute>

						<PrivateRoute
							exact
							path="/transaction-medical-services/:transactionHealthServiceId"
						>
							<TransactionHealthServiceDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/transaction-medical-services">
							<TransactionHealthService isTest={false} />
						</PrivateRoute>
						<PrivateRoute exact path="/transaction-medical-services-test">
							<TransactionHealthService isTest={true} />
						</PrivateRoute>

						<PrivateRoute exact path="/profile/edit">
							<EditProfile />
						</PrivateRoute>
						<PrivateRoute exact path="/profile">
							<Profile />
						</PrivateRoute>
						<PrivateRoute exact path="/change-password">
							<ChangeMyPassword />
						</PrivateRoute>

						<PrivateRoute exact path="/article/:articleId/edit">
							<ArticleEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/article/add">
							<ArticleEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/article/:articleId/detail">
							<ArticleDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/article">
							<Article />
						</PrivateRoute>

						<PrivateRoute exact path="/medicine/:drugMedicineId/edit">
							<MedicineEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine/add">
							<MedicineEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine/:drugMedicineId/detail">
							<MedicineDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine">
							<Medicine />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine/:drugMedicineId/history">
							<DetailMedicineHistory />
						</PrivateRoute>

						<PrivateRoute exact path="/medical-event/:eventId/edit">
							<EventEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event/add">
							<EventEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event/:eventId/detail">
							<EventDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event">
							<Event />
						</PrivateRoute>

						<PrivateRoute exact path="/product/:productId/edit">
							<ProductEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/product/add">
							<ProductEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/product/:productId/detail">
							<ProductDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/product">
							<Product />
						</PrivateRoute>

						<PrivateRoute
							exact
							path="/medical-service-individual/:healthServiceId/edit"
						>
							<IndividualEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-individual/add">
							<IndividualEdit />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/medical-service-individual/:healthServiceId/detail"
						>
							<IndividualDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-individual">
							<IndividualService />
						</PrivateRoute>

						<PrivateRoute
							exact
							path="/medical-service-package/:healthServiceId/edit"
						>
							<PackageEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-package/add">
							<PackageEdit />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/medical-service-package/:healthServiceId/detail"
						>
							<PackageDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-package">
							<PackageService />
						</PrivateRoute>

						<PrivateRoute
							exact
							path="/medical-service-category/:categoryId/edit"
						>
							<HealthServiceCategoryEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-category/add">
							<HealthServiceCategoryEdit />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/medical-service-category/:categoryId/detail"
						>
							<HealthServiceCategoryDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-service-category">
							<HealthServiceCategory />
						</PrivateRoute>

						{/* Tag Management */}
						<PrivateRoute exact path="/tag/:tagId/edit">
							<TagEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/tag/add">
							<TagEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/tag/:tagId/detail">
							<TagDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/tag">
							<Tag />
						</PrivateRoute>

						<PrivateRoute exact path="/doctor-specialization">
							<Specialization />
						</PrivateRoute>

						<PrivateRoute exact path="/medicine-tags/:categoryId/edit">
							<MedicineCategoryEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine-tags/add">
							<MedicineCategoryEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine-tags/:categoryId/detail">
							<MedicineCategoryDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medicine-tags">
							<MedicineCategory />
						</PrivateRoute>

						<PrivateRoute exact path="/medical-event-tag/:tagId/edit">
							<EventTagEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event-tag/add">
							<EventTagEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event-tag/:tagId/detail">
							<EventTagDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/medical-event-tag">
							<EventTag />
						</PrivateRoute>

						<PrivateRoute exact path="/topic/:topicId/edit">
							<TopicEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/topic/add">
							<TopicEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/topic/:topicId/detail">
							<TopicDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/topic">
							<Topic />
						</PrivateRoute>

						<PrivateRoute exact path="/category/:categoryId/edit">
							<CategoryEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/category/add">
							<CategoryEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/category/:categoryId/detail">
							<CategoryDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/category">
							<Category />
						</PrivateRoute>
						<PrivateRoute exact path="/admins">
							<Admin />
						</PrivateRoute>
						<PrivateRoute exact path="/admins/add">
							<AdminEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/admins/:userId/edit">
							<AdminEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/admins/:userId">
							<AdminDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/admins/:userId/change-password">
							<ChangePasswordAdmin />
						</PrivateRoute>

						{/* Customer Management Group */}
						<PrivateRoute exact path="/patients">
							<Customer />
						</PrivateRoute>
						<PrivateRoute exact path="/patients/:userId">
							<CustomerDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/patients/:userId/edit">
							<CustomerEdit />
						</PrivateRoute>

						<PrivateRoute exact path="/relative">
							<Relative />
						</PrivateRoute>

						<PrivateRoute exact path="/secretary">
							<Secretary />
						</PrivateRoute>
						<PrivateRoute exact path="/secretary/:userId">
							<SecretaryDetail />
						</PrivateRoute>

						<PrivateRoute exact path="/doctor">
							<Doctor />
						</PrivateRoute>
						<PrivateRoute exact path="/doctor/:doctorId">
							<DoctorDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/doctor/:doctorId/edit">
							<DoctorEdit />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/doctor/:doctorId/submisson/:submissionId"
						>
							<DoctorDetail />
						</PrivateRoute>

						{/* Content Management Group */}
						<PrivateRoute exact path="/voucher">
							<Voucher />
						</PrivateRoute>
						<PrivateRoute exact path="/voucher/add">
							<VoucherEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/voucher/:voucherId">
							<VoucherDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/voucher/:voucherId/edit">
							<VoucherEdit />
						</PrivateRoute>

						<PrivateRoute exact path="/promodeals">
							<PromoDeals />
						</PrivateRoute>
						<PrivateRoute exact path="/promodeals/add">
							<PromoDealsEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/promodeals/:bannerId">
							<PromoDealsDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/promodeals/:bannerId/edit">
							<PromoDealsEdit />
						</PrivateRoute>

						<PrivateRoute exact path="/notification">
							<Notification />
						</PrivateRoute>

						{/* Partner Management Group */}
						<PrivateRoute exact path="/clinic">
							<Partner />
						</PrivateRoute>
						<PrivateRoute exact path="/clinic/add">
							<PartnerEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/clinic/:partnerId">
							<PartnerDetail />
						</PrivateRoute>
						<PrivateRoute exact path="/clinic/:partnerId/edit">
							<PartnerEdit />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/clinic/:partnerId/:type/:healthServiceId/edit"
						>
							<PartnerServiceEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/clinic/:partnerId/:type/add">
							<PartnerServiceEdit />
						</PrivateRoute>

						{/* Customer Feedback */}
						<PrivateRoute exact path="/customer-feedbacks">
							<CustomerFeedback />
						</PrivateRoute>

						{/* FAQ General */}
						<PrivateRoute exact path="/faq">
							<FAQ />
						</PrivateRoute>
						<PrivateRoute exact path="/faq/add">
							<FAQEdit />
						</PrivateRoute>
						<PrivateRoute exact path="/faq/:faqId/edit">
							<FAQEdit />
						</PrivateRoute>

						{/* FAQ Balance */}
						<PrivateRoute exact path="/balance-information">
							<FAQBalance />
						</PrivateRoute>
						<PrivateRoute exact path="/balance-information/add">
							<FAQEditBalance />
						</PrivateRoute>
						<PrivateRoute exact path="/balance-information/:faqId/edit">
							<FAQEditBalance />
						</PrivateRoute>

						{/* FAQ Point */}
						<PrivateRoute exact path="/point-information">
							<FAQPoint />
						</PrivateRoute>
						<PrivateRoute exact path="/point-information/add">
							<FAQEditPoint />
						</PrivateRoute>
						<PrivateRoute exact path="/point-information/:faqId/edit">
							<FAQEditPoint />
						</PrivateRoute>

						{/* Privacy Policy */}
						<PrivateRoute exact path="/privacy-policy">
							<PrivacyPolicyEdit />
						</PrivateRoute>

						{/* Privacy Policy */}
						<PrivateRoute exact path="/referral-information">
							<ReferralInformationEdit />
						</PrivateRoute>

						{/* Medical Calculator */}
						<PrivateRoute exact path="/medical-calculator">
							<MedicalCalculatorEdit />
						</PrivateRoute>

						{/* Terms & Conditions */}
						<PrivateRoute exact path="/terms-and-conditions">
							<TnCEdit />
						</PrivateRoute>

						{/* Terms & Conditions */}
						<PrivateRoute exact path="/chat-customer-service">
							<ChatCustomerServiceEdit />
						</PrivateRoute>

						{/* Fees */}
						<PrivateRoute exact path="/fees">
							<Fees />
						</PrivateRoute>
						<PrivateRoute exact path="/fees/:key">
							<FeesDetail />
						</PrivateRoute>

						{/* Reward Point Settings */}
						<PrivateRoute exact path="/points">
							<RewardPointSettings />
						</PrivateRoute>
						<PrivateRoute exact path="/points/:key">
							<RewardPointSettingsDetails />
						</PrivateRoute>

						{/* Pharmacy */}
						<PrivateRoute exact path="/pharmacy">
							<Pharmacy />
						</PrivateRoute>
						<PrivateRoute exact path="/pharmacy/add">
							<AddEditPharmacy />
						</PrivateRoute>
						<PrivateRoute exact path="/pharmacy/:partnerId/details">
							<DetailPharmacy />
						</PrivateRoute>
						<PrivateRoute exact path="/pharmacy/:partnerId/edit">
							<AddEditPharmacy />
						</PrivateRoute>
						<PrivateRoute
							exact
							path="/pharmacy/:partnerId/details/:drugMedicineId"
						>
							<DetailPharmacyMedicine />
						</PrivateRoute>

						{/* Withdraw */}
						<PrivateRoute exact path="/withdrawal">
							<Withdrawal />
						</PrivateRoute>

						{/* Redeem */}
						<PrivateRoute exact path="/redeem">
							<Redeem />
						</PrivateRoute>
					</Switch>
				</AppLayout>
			)}
		</Router>
	);
};

export default RootNavigator;
