import React from 'react';
import styled from 'styled-components';

const AuthLayout: React.FC = ({ children }) => {
	return (
		<Container>
			<ContentLeft />
			<ContentRight>{children}</ContentRight>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: grid;
	grid-template-columns: 35% 1fr;
`;

const ContentLeft = styled.div`
	background-image: url('/images/bg-login.jpg');
	background-repeat: no-repeat;
	background-size: cover;
`;

const ContentRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.ash400};
`;

export default AuthLayout;
