import {
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Row,
	Col,
	TimePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	EVoucherStatus,
	FetchAllVoucherResponse,
	initialVoucher,
	VoucherProps,
} from '../../types/voucher.type';

interface IParams {
	voucherId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: VoucherProps;
}

const { Option } = Select;

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const VoucherEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { voucherId } = useParams<IParams>();
	const formRef =
		React.useRef<FormInstance<Omit<VoucherProps, 'createdAt' | 'updatedAt'>>>(
			null,
		);
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProps>(initialVoucher);

	const [image, setImage] = React.useState<File | undefined>();
	const [imageUrl, setImageUrl] = React.useState<string | undefined>();

	const [isSelected, setIsSelected] = React.useState(true);
	const [isSelected2, setIsSelected2] = React.useState(true);
	const [isSelected3, setIsSelected3] = React.useState(true);

	const [voucherStatus, setVoucherStatus] = React.useState(
		EVoucherStatus.AVAILABLE,
	);
	const [data, setData] = React.useState<Array<VoucherProps>>([]);
	const [validateCode, setValidateCode] = React.useState<boolean>(true);

	const [minimumTransaction, setMinimumTransaction] = React.useState<
		number | undefined
	>(0);
	const [limitMaxUsePerPerson, setLimitMaxUsePerPerson] = React.useState<
		number | undefined
	>(undefined);
	const [maxDiscount, setMaxDiscount] = React.useState<number | undefined>(0);

	const [validStartAt, setValidStartAt] = React.useState<Date | undefined>(
		voucher.validStartAt ? undefined : voucher.validStartAt,
	);
	const [validEndAt, setValidEndAt] = React.useState<Date | undefined>(
		voucher.validEndAt ? undefined : voucher.validEndAt,
	);

	const [discountValueError, setDiscountValueError] = React.useState<
		string | null
	>(null);

	const [createdDatePickerValue, setCreatedDatePickerValue] =
		React.useState<Moment | null>(null);
	const [expiredDatePickerValue, setExpiredDatePickerValue] =
		React.useState<Moment | null>(null);

	const [createdTimePickerValue, setCreatedTimePickerValue] =
		React.useState<Moment | null>(null);
	const [expiredTimePickerValue, setExpiredTimePickerValue] =
		React.useState<Moment | null>(null);

	const [isNominal, setIsNominal] = React.useState<boolean>(true);

	const [notifSendAt, setNotifSendAt] = React.useState<Date | null>(null);

	const handleVoucherStatus = (
		startAt: Date | undefined,
		endAt: Date | undefined,
	) => {
		if (moment().isBefore(startAt)) {
			return EVoucherStatus.PENDING;
		} else if (moment().isAfter(endAt)) {
			return EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(startAt, endAt)) {
			return EVoucherStatus.AVAILABLE;
		} else {
			return EVoucherStatus.USED;
		}
	};

	const handleValidStartAt = (time: Moment | null) => {
		let date1 = createdDatePickerValue;
		let time1 = time;
		let dateValue1 = moment({
			date: date1?.get('date'),
			month: date1?.month(),
			year: date1?.year(),
		}).format('YYYY-MM-DD');

		// let timeValue1 = moment({
		// 	hour: time1?.hour(),
		// 	minute: time1?.minute(),
		// 	second: time1?.second(),
		// });

		let status: EVoucherStatus;

		let final1 = dateValue1.toString().substring(0, 15) + ' ';

		if (moment().isBefore(validStartAt)) {
			status = EVoucherStatus.PENDING;
		} else if (moment().isAfter(validEndAt)) {
			status = EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(validStartAt, validEndAt)) {
			status = EVoucherStatus.AVAILABLE;
		} else {
			status = EVoucherStatus.USED;
		}

		setVoucher({
			...voucher,
			validStartAt: new Date(final1),
			voucherStatus: status,
		});
	};

	const handleValidEndAt = (time: Moment | null) => {
		let date2 = expiredDatePickerValue;
		let time2 = time;
		let dateValue2 = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		// let timeValue2 = moment({
		// 	hour: time2?.hour(),
		// 	minute: time2?.minute(),
		// 	second: time2?.second(),
		// });

		let status;

		let final2 = dateValue2.toString().substring(0, 15) + ' ';

		if (moment().isBefore(validStartAt)) {
			status = EVoucherStatus.PENDING;
		} else if (moment().isAfter(validEndAt)) {
			status = EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(validStartAt, validEndAt)) {
			status = EVoucherStatus.AVAILABLE;
		} else {
			status = EVoucherStatus.USED;
		}

		setVoucher({
			...voucher,
			validEndAt: new Date(final2),
			voucherStatus: status,
		});
	};

	const saveValidStartDate = (date: Moment | null) => {
		let date2 = date;
		let dateValue = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		let timeValue = moment({
			hour: date2?.hour(),
			minute: date2?.minute(),
			second: date2?.second(),
		});

		let final =
			dateValue.toString().substring(0, 15) +
			' ' +
			timeValue.toDate().toString().substring(16, 24);
		setValidStartAt(new Date(final));
	};

	const saveValidEndDate = (date: Moment | null) => {
		let date2 = date;
		let dateValue = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		let timeValue = moment({
			hour: date2?.hour(),
			minute: date2?.minute(),
			second: date2?.second(),
		});

		let final =
			dateValue.toString().substring(0, 15) +
			' ' +
			timeValue.toDate().toString().substring(16, 24);
		setValidEndAt(new Date(final));
	};

	const onCreatedTimePickerChange = (time: Moment | null) => {
		// setCreatedTimePickerValue(time);
		let dateTime = time;
		let timeValue = moment({
			hour: dateTime?.hour(),
			minute: dateTime?.minute(),
			second: dateTime?.second(),
		});
		// setCreatedTimePickerValue(timeValue);

		// setVoucher({
		//   ...voucher,
		//   // validStartAt: createdTimePickerValue ? createdTimePickerValue.toDate() : new Date(),
		//   // validStartAt: timeValue.toDate()
		// })
	};

	const onCreatedDatePickerChange = (date: Moment | null) => {
		// setCreatedDatePickerValue(date);
		// setVoucher({
		//   ...voucher,
		//   validStartAt: createdDatePickerValue ? createdDatePickerValue.toDate() : new Date(),
		// })
		let dateTime = date;
		let dateValue = moment({
			date: dateTime?.date(),
			month: dateTime?.month(),
			year: dateTime?.year(),
		});
		setCreatedDatePickerValue(dateValue);
		handleValidStartAt(date);
	};

	const onExpiredTimePickerChange = (time: Moment | null) => {
		// setExpiredTimePickerValue(time);
		// setVoucher({
		//   ...voucher,
		//   validEndAt: expiredTimePickerValue ? expiredTimePickerValue.toDate() : new Date(),
		// })
		let dateTime = time;
		let timeValue = moment({
			hour: dateTime?.hour(),
			minute: dateTime?.minute(),
			second: dateTime?.second(),
		});
		// setExpiredTimePickerValue(timeValue);
		// console.log(timeValue);
	};

	const onExpiredDatePickerChange = (date: Moment | null) => {
		// setExpiredDatePickerValue(date);
		// setVoucher({
		//   ...voucher,
		//   validEndAt: expiredDatePickerValue ? expiredDatePickerValue.toDate() : new Date(),
		// })
		let dateTime = date;
		let dateValue = moment({
			date: dateTime?.date(),
			month: dateTime?.month(),
			year: dateTime?.year(),
		});
		setExpiredDatePickerValue(dateValue);
	};

	const createVoucher = async (
		props: Omit<
			VoucherProps,
			'createdAt' | 'updatedAt' | 'voucherCode' | 'statusLoading'
		>,
	) => {
		try {
			const checkVoucherStatus = handleVoucherStatus(validStartAt, validEndAt);

			const formData = new FormData();

			if (image) {
				formData.append('imageUrl', image);
			}

			setIsLoadingAction(true);
			const res: any = await httpRequest.post('/vouchers', {
				...props,
				voucherName: voucher.voucherName,
				description: voucher.description,
				termAndCondition: voucher.termAndCondition,
				discountValue: voucher.discountValue,
				maxDiscountValue: voucher.maxDiscountValue,
				limitMaxUsePerPerson: voucher.limitMaxUsePerPerson,
				requirement: voucher.requirement.minimumTransaction,
				validStartAt: validStartAt,
				validEndAt: validEndAt,
				voucherStatus: checkVoucherStatus,
				voucherType: voucher.voucherType,
				notifSendAt: notifSendAt,
			});

			if (res && res.data) {
				console.info('res.data', res.data);
				await uploadNewImage(res.data.payload.voucherId);
			}

			message.success('Success create ' + props.voucherName);
			history.push('/voucher');
			setIsLoadingAction(false);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateVoucher = async (
		props: Omit<VoucherProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			const checkVoucherStatus = handleVoucherStatus(validStartAt, validEndAt);

			setIsLoadingAction(true);

			const res: any = {
				...props,
				voucherName: voucher.voucherName,
				description: voucher.description,
				termAndCondition: voucher.termAndCondition,
				discountValue: voucher.discountValue,
				maxDiscountValue: voucher.maxDiscountValue,
				limitMaxUsePerPerson: voucher.limitMaxUsePerPerson,
				requirement: voucher.requirement.minimumTransaction,
				validStartAt: validStartAt,
				validEndAt: validEndAt,
				voucherStatus: checkVoucherStatus,
				voucherType: voucher.voucherType,
				notifSendAt: notifSendAt,
			};

			if (voucherId) {
				await Promise.all([
					httpRequest.patch('/vouchers/' + voucherId, res),
					uploadNewImage(voucherId),
				]);
			}

			message.success('Success update ' + props.voucherName);
			history.push('/voucher');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const uploadNewImage = async (voucherId: string) => {
		try {
			if (image) {
				setIsLoadingAction(true);

				let formData = new FormData();
				formData.append('image', image);

				await httpRequest.put(
					'/vouchers/' + voucherId + '/upload-image',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
				setIsLoadingAction(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleSubmit = async (
		values: Omit<VoucherProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		console.info('values', values);
		// if (!image && !voucher.imageUrl) {
		// 	message.error('Cannot submit with empty image');
		// 	return;
		// }

		if (voucherId) {
			updateVoucher(values);
		} else {
			createVoucher(values);
		}
	};

	const fetchList = async () => {
		const res = await httpRequest.get<FetchAllVoucherResponse>(`/vouchers`);

		const newRes = res.data.payload.results.map((item) => {
			return {
				...item,
			};
		});

		setData(newRes);
	};

	React.useEffect(() => {
		fetchList();
		if (voucherId) {
			const fetchVoucher = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<ResponseProps>(
						'/vouchers/' + voucherId,
					);
					const initVoucher = res.data.payload;
					setVoucher(initVoucher);
					setImageUrl(initVoucher.imageUrl);

					setMaxDiscount(initVoucher.maxDiscountValue);
					setLimitMaxUsePerPerson(initVoucher.limitMaxUsePerPerson);
					setMinimumTransaction(initVoucher.requirement.minimumTransaction);
					setIsNominal(initVoucher.discountType == 'nominal' ? true : false);

					form.setFieldsValue({
						startAt: moment(initVoucher.validStartAt),
						validStartAt: moment(initVoucher.validStartAt),
						limitMaxUsePerPerson: initVoucher.limitMaxUsePerPerson,
					});

					setValidStartAt(initVoucher.validStartAt);
					setValidEndAt(initVoucher.validEndAt);
					setNotifSendAt(
						initVoucher.notifSendAt ? initVoucher.notifSendAt : null,
					);
					console.log(initVoucher);
					if (initVoucher.limitMaxUsePerPerson === 0) {
						setIsSelected2(false);
					} else {
						setIsSelected2(true);
					}

					const bcDetails = [
						{
							field: 'voucherCode',
							value: voucher.voucherCode,
							label: res.data.payload.voucherName || '',
						},
					];
					setBreadcrumbDetails(bcDetails);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchVoucher();
		}
	}, [voucherId]);

	const initialFormValues = voucherId
		? {
				...voucher,
				validStartAt: moment(voucher.validStartAt),
				startAt: moment(voucher.validStartAt),
				validEndAt: moment(voucher.validEndAt),
				endAt: moment(voucher.validEndAt),
		  }
		: initialVoucher;

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Vouchers"
				subtitle="Manage your Vouchers data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
							disabled={validateCode ? false : true}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="voucherForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={initialFormValues}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Information"
						helpers={[
							{
								title: 'Information',
								content: (
									<div
										style={{
											maxWidth: '80%',
											wordWrap: 'break-word',
											overflow: 'hidden',
											whiteSpace: 'normal',
										}}
									>
										Accepted image type are jpg, jpeg and png. Max file size is
										1 Mb.
									</div>
								),
							},
						]}
					>
						<h4 style={{ color: '#556575' }}>Vouchers image</h4>
						<Form.Item
							name="image"
							rules={[
								{
									required: !imageUrl,
									message: 'Image is Required',
								},
							]}
						>
							<FormUploadImage
								mode={'single-large'}
								maxSizeInMB={1}
								imageUrl={imageUrl || ''}
								onChange={(file) => {
									setImage(file);
								}}
								onPreviewChange={(previewUrl) => setImageUrl(previewUrl)}
							/>
						</Form.Item>

						<Form.Item
							label="Headline"
							name="voucherName"
							rules={[
								{
									required: true,
									message: 'Headline is required.',
								},
							]}
						>
							<Input
								value={voucher.voucherName}
								placeholder="Input headline"
								onChange={function (e) {
									setVoucher({
										...voucher,
										voucherName: e.target.value,
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Detail Vouchers" name="description" rules={[]}>
							<TextArea
								placeholder="Input detail voucher"
								value={voucher.description}
								onChange={(e) => {
									setVoucher({
										...voucher,
										description: e.target.value,
									});
								}}
							></TextArea>
						</Form.Item>

						<Form.Item
							label="Term & Condition"
							name="termAndCondition"
							rules={[]}
						>
							<ReactQuill
								theme="snow"
								placeholder="Input term and condition"
								onChange={(val) =>
									setVoucher({
										...voucher,
										termAndCondition: val,
									})
								}
								value={voucher.termAndCondition}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group value={voucher.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>

					<Divider style={{ width: '100%' }} />

					<SectionContent
						groupTitle="Discount Setting"
						helpers={[
							{
								title: 'Information',
								content:
									'Tag name must be unique, system will reject if tag has been created with the same name.',
							},
						]}
					>
						<Form.Item
							label="Discount Code"
							name="voucherCode"
							rules={[
								{
									required: true,
									message: 'The Discount Code is required.',
								},
							]}
						>
							<Input
								onChange={function (e) {
									data.forEach((item) => {
										if (item.voucherCode == e.target.value) {
											alert('Voucher Code is already exist!');
											setValidateCode(false);
										} else {
											setValidateCode(true);
											setVoucher({
												...voucher,
												voucherCode: e.target.value,
											});
										}
									});
								}}
								value={voucher.voucherCode}
								placeholder="Input code"
								disabled={voucherId ? true : false}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Discount Type"
							name="discountType"
							rules={[]}
						>
							<Radio.Group value={voucher.discountType}>
								<CustomRadio
									value={'percentage'}
									onChange={() => {
										setIsNominal(false);
									}}
								>
									Percentage
								</CustomRadio>
								<CustomRadio
									value={'nominal'}
									onChange={() => {
										setIsNominal(true);
									}}
								>
									Nominal
								</CustomRadio>
							</Radio.Group>
						</Form.Item>

						<Form.Item
							label="Discount Value"
							name="discountValue"
							rules={[
								{
									required: true,
									message: 'The Discount Value is required.',
								},
							]}
						>
							<Input
								type="number"
								value={voucher.discountValue}
								onChange={(e) => {
									const inputValue = parseFloat(e.target.value);
									if (!isNaN(inputValue) && inputValue > 0) {
										setVoucher({
											...voucher,
											discountValue: inputValue,
										});
									} else {
										form.setFields([
											{
												name: 'discountValue',

												errors: ['Discount value must be greater than 0'],
											},
										]);
									}
								}}
								prefix={isNominal && '₱'}
								suffix={!isNominal && '%'}
								placeholder="Input discount value"
								width={'100%'}
							/>
						</Form.Item>

						{!isNominal && (
							<Form.Item
								style={{
									paddingTop: '2%',
								}}
								label="Max Discount"
								name="maxDiscountValue"
								rules={generateFormRules('Max Discount Value', ['required'])}
							>
								<Radio.Group value={voucher.maxDiscountValue ? 1 : -1}>
									<CustomRadio
										value={maxDiscount! > 0 ? maxDiscount : -1}
										onChange={() => setIsSelected(true)}
										style={{
											margin: '0',
										}}
									>
										<Input
											onChange={function (e) {
												if (!isNaN(parseInt(e.target.value)))
													setVoucher({
														...voucher,
														maxDiscountValue: parseFloat(e.target.value),
													});
												setIsSelected(true);
											}}
											disabled={isSelected ? false : true}
											prefix="₱"
											placeholder="Input max discount"
											value={voucher.maxDiscountValue}
											style={{ width: '18vw' }}
										/>
									</CustomRadio>
									<CustomRadio
										value={maxDiscount! == 0 ? maxDiscount : -1}
										onChange={function () {
											setIsSelected(false);
											setVoucher({
												...voucher,
												maxDiscountValue: 0,
											});
										}}
										style={{
											margin: '0',
										}}
									>
										No max discount
									</CustomRadio>
								</Radio.Group>
							</Form.Item>
						)}

						<Row gutter={[16, 0]}>
							<Col span={12}>
								<Form.Item
									label="Limit Per User"
									name="limitMaxUsePerPerson"
									rules={[
										{
											required: true,
											message: 'Please input the usage limit per user.',
										},
									]}
								>
									<Input
										type="number"
										onChange={(e) => {
											const value = parseInt(e.target.value);

											if (!isNaN(value)) {
												setLimitMaxUsePerPerson(value);
												setVoucher({
													...voucher,
													limitMaxUsePerPerson: value,
												});
											}
										}}
										disabled={!isSelected2}
										suffix="Usage"
										placeholder="Input limit per user"
										value={limitMaxUsePerPerson}
										width={'100%'}
									/>
								</Form.Item>
							</Col>
							<Col span={12} style={{ marginTop: '32px' }}>
								<Form.Item
									name="limitMaxUsePerPerson"
									labelCol={{ span: 0 }}
									noStyle
									rules={[
										{
											required: true,
											message:
												'Please select or input the usage limit per user.',
										},
									]}
								>
									<Radio.Group>
										<CustomRadio
											value={null}
											onChange={() => setIsSelected2(true)}
											style={{ margin: '0' }}
										>
											Usage
										</CustomRadio>
										<CustomRadio
											value={0}
											style={{ margin: '0' }}
											onChange={() => {
												setIsSelected2(false);
												setVoucher({
													...voucher,
													limitMaxUsePerPerson: 0,
												});
											}}
										>
											No usage limit per user
										</CustomRadio>
									</Radio.Group>
								</Form.Item>
							</Col>
						</Row>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Minimum Transaction"
							name="requirement"
							rules={generateFormRules('Minimum Transaction', ['required'])}
						>
							<Radio.Group
								value={voucher.requirement.minimumTransaction ? 1 : -1}
							>
								<CustomRadio
									value={voucher.requirement.minimumTransaction}
									onChange={() => setIsSelected3(true)}
									style={{
										margin: '0',
									}}
								>
									<Input
										onChange={function (e) {
											if (!isNaN(parseInt(e.target.value)))
												setVoucher({
													...voucher,
													requirement: {
														minimumTransaction: parseFloat(e.target.value),
													},
												});
										}}
										disabled={isSelected3 ? false : true}
										prefix="₱"
										placeholder="Input minimum transaction"
										style={{ width: '18vw' }}
										value={voucher.requirement.minimumTransaction}
									/>
								</CustomRadio>
								<CustomRadio
									value={-1}
									style={{
										margin: '0',
									}}
									onChange={function () {
										setIsSelected3(false);
										setVoucher({
											...voucher,
											requirement: {
												minimumTransaction: 0,
											},
										});
									}}
								>
									No minimum transaction
								</CustomRadio>
							</Radio.Group>
						</Form.Item>

						<Form.Item name="validStartAt" hidden>
							<div>
								<DatePicker
									value={
										voucher.validStartAt
											? moment(voucher.validStartAt)
											: undefined
									}
								/>
							</div>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="From"
							name="startAt"
							rules={[
								{
									required: true,
									message: 'Please input the valid end date and time.',
								},
							]}
						>
							<CustomDatePicker
								defaultValue={
									voucher.validStartAt
										? moment(voucher.validStartAt)
										: undefined
								}
								format={'DD/MM/YYYY HH:mm'}
								showTime={{ format: 'HH:mm' }}
								style={{ width: '55%' }}
								placeholder="Select date and time"
								onChange={(e) => {
									handleValidStartAt(e);
									onCreatedDatePickerChange(e);
								}}
								onSelect={(e) => {
									saveValidStartDate(e);
									handleValidStartAt(e);
								}}
								size="large"
							/>
						</Form.Item>
						<Form.Item name="validEndAt" hidden>
							<div>
								<DatePicker
									value={
										voucher.validEndAt ? moment(voucher.validEndAt) : undefined
									}
								/>
							</div>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="To"
							name="endAt"
							rules={[
								{
									required: true,
									message: 'Please input the valid end date and time.',
								},
							]}
						>
							<CustomDatePicker
								defaultValue={
									voucher.validEndAt ? moment(voucher.validEndAt) : undefined
								}
								showTime={{ format: 'HH:mm' }}
								format={'DD/MM/YYYY HH:mm'}
								style={{ width: '55%' }}
								placeholder="Select date and time"
								onChange={(e) => {
									onExpiredDatePickerChange(e);
									handleValidEndAt(e);
								}}
								onSelect={(e) => {
									saveValidEndDate(e);
									handleValidEndAt(e);
								}}
								size="large"
							/>
						</Form.Item>
						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Send Notification"
							name="notifSendAt"
							rules={[]}
						>
							<Radio.Group value={notifSendAt}>
								<CustomRadio
									value={null}
									onChange={() => {
										setNotifSendAt(null);
									}}
								>
									Yes
								</CustomRadio>
								<CustomRadio
									value={!!voucher.notifSendAt && voucher.notifSendAt}
									onChange={() => {
										setNotifSendAt(new Date());
									}}
								>
									No
								</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default VoucherEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
	.ant-form-item-explain {
		display: none;
	}
	.radio-button-container {
		margin-top: 32px;
	}
`;

const CustomDatePicker = styled(DatePicker)`
	margin-right: 10px;
	.ant-picker .ant-picker-input,
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 0;
		margin-right: 5px;
	}
`;
