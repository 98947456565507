import {
	TagOutlined,
	FileImageOutlined,
	MessageOutlined,
	TransactionOutlined,
	HomeOutlined,
	UnorderedListOutlined,
	FileTextOutlined,
	WifiOutlined,
	UserOutlined,
	AppstoreOutlined,
	GiftOutlined,
	CreditCardOutlined,
	FileOutlined,
	QuestionCircleOutlined,
	StarOutlined,
	SendOutlined,
	CalculatorOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { EBatchPermissions } from '../helpers/permissions';
import useConfigApp from '../hooks/useConfigApp';
import AppMenuItem, { MenuSidebarProps } from './AppMenuItem';
import { ReactComponent as FeesLogo } from '../assets/icons/fees_icon.svg';
import { ReactComponent as HardDriveLogo } from '../assets/icons/hard_drive.svg';
import useApproval from '../hooks/useApproval';

const AppMenuSidebar: React.FC = () => {
	const location = useLocation();
	const { activeSubMenu, toggleActiveSideMenu } = useConfigApp();
	const { patientlength, refundLength } = useApproval();

	const dummyUser = {
		permissions: [
			'W024',
			'W030',
			'W036',
			'W042',
			'W004',
			'W009',
			'W014',
			'W019',
			'WO48',
			'W014',
			'W019',
			'W009',
		],
	};

	const menus: MenuSidebarProps['data'][] = [
		{
			groupTitle: 'Dashboard',
			menus: [
				{
					permission: dummyUser.permissions.includes(EBatchPermissions.VIEW),
					label: 'Dashboard',
					icon: <AppstoreOutlined />,
					counter: 0,
					path: '/dashboard',
				},
			],
		},
		{
			groupTitle: 'Transaction',
			menus: [
				{
					permission: true,
					key: 'transaction',
					label: 'Transaction',
					icon: <TransactionOutlined />,
					counter: refundLength,
					subMenuTitleGroup: 'Transaction',
					subMenu: true,
					subMenuItems: [
						{
							label: 'Consultation',
							path: '/transaction-consultations',
							counter: refundLength,
						},
						{
							label: 'Medical Service',
							path: '/transaction-medical-services',
							counter: 0,
						},
						{
							label: 'Prescription & Lab Request',
							path: '/transaction-prescription-labrequest',
							counter: 0,
						},
					],
				},
				{
					permission: true,
					key: 'withdrawalAndRedeem',
					label: 'withdrawalAndRedeem',
					icon: <SendOutlined rotate={315} />,
					counter: 0,
					subMenuTitleGroup: 'Withdrawal & Redeem',
					subMenu: true,
					subMenuItems: [
						{
							label: 'Withdrawal',
							path: '/withdrawal',
							counter: 0,
						},
						{
							label: 'Redeem',
							path: '/redeem',
							counter: 0,
						},
					],
				},
			],
		},
		{
			groupTitle: 'Service',
			menus: [
				{
					permission: true,
					key: 'medical-service',
					label: 'Medical Service',
					icon: <FileImageOutlined />,
					counter: 0,
					subMenuTitleGroup: 'Medical Service',
					subMenu: true,
					subMenuItems: [
						{
							label: 'Individual',
							path: '/medical-service-individual',
							counter: 0,
						},
						{
							label: 'Package',
							path: '/medical-service-package',
							counter: 0,
						},
						{
							label: 'Category',
							path: '/medical-service-category',
							counter: 0,
						},
					],
				},
			],
		},
		{
			groupTitle: 'Partner',
			menus: [
				{
					permission: true,
					label: 'Pharmacy',
					icon: <HardDriveLogo />,
					counter: 0,
					path: '/pharmacy',
				},
				{
					permission: true,
					label: 'Clinics',
					icon: <HomeOutlined />,
					counter: 0,
					path: '/clinic',
				},
			],
		},
		{
			groupTitle: 'Master Data',
			menus: [
				{
					permission: true,
					key: 'medicine',
					label: 'Medicine',
					icon: <UnorderedListOutlined />,
					counter: 0,
					subMenuTitleGroup: 'Medicine',
					subMenu: true,
					subMenuItems: [
						{
							label: 'Medicine',
							path: '/medicine',
							counter: 0,
						},
						{
							label: 'Medicine Tag',
							path: '/medicine-tags',
							counter: 0,
						},
					],
				},
				{
					permission: true,
					label: 'Doctor Specialization',
					icon: <TagOutlined rotate={270} />,
					counter: 0,
					path: '/doctor-specialization',
				},
			],
		},
		{
			groupTitle: 'Content',
			menus: [
				{
					permission: true,
					label: 'Promo Deals',
					icon: <TagOutlined rotate={270} />,
					counter: 0,
					path: '/promodeals',
				},
				{
					permission: true,
					key: 'article',
					label: 'Article',
					icon: <FileTextOutlined />,
					counter: 0,
					subMenuTitleGroup: 'Article',
					subMenu: true,
					subMenuItems: [
						{
							label: 'Article',
							path: '/article',
							counter: 0,
						},
						{
							label: 'Article Tag',
							path: '/topic',
							counter: 0,
						},
					],
				},
				{
					permission: true,
					key: 'medical-event',
					label: 'Medical Event',
					icon: <WifiOutlined rotate={45} />,
					counter: 0,
					subMenuTitleGroup: 'Medical Event',
					subMenu: true,
					subMenuItems: [
						{
							permission: true,
							label: 'Medical Event',
							icon: <FileImageOutlined />,
							counter: 0,
							path: '/medical-event',
						},
						{
							permission: dummyUser.permissions.includes(
								EBatchPermissions.VIEW,
							),
							label: 'Medical Event Tag',
							icon: <TagOutlined />,
							counter: 0,
							path: '/medical-event-tag',
						},
					],
				},
				{
					permission: true,
					label: 'Vouchers',
					icon: <TagOutlined rotate={270} />,
					counter: 0,
					path: '/voucher',
				},
				{
					permission: true,
					label: 'Notification',
					icon: <TagOutlined rotate={270} />,
					counter: 0,
					path: '/notification',
				},
			],
		},
		{
			groupTitle: 'Users',
			menus: [
				{
					permission: true,
					label: 'Admin',
					icon: <UserOutlined />,
					counter: 0,
					path: '/admins',
				},
				{
					permission: true,
					label: 'Doctor',
					icon: <UserOutlined />,
					counter: 0,
					path: '/doctor',
				},
				{
					permission: true,
					label: 'Secretary',
					icon: <UserOutlined />,
					counter: 0,
					path: '/secretary',
				},
				{
					permission: true,
					label: 'Patient',
					icon: <UserOutlined />,
					counter: patientlength,
					path: '/patients',
				},
				{
					permission: true,
					label: 'Relative',
					icon: <UserOutlined />,
					counter: 0,
					path: '/relative',
				},
				{
					permission: true,
					label: 'Bank Account Approval',
					icon: <CreditCardOutlined />,
					counter: 0,
					path: '/bank-approval',
				},
			],
		},
		{
			groupTitle: 'Configuration',
			menus: [
				{
					permission: dummyUser.permissions.includes(EBatchPermissions.VIEW),
					label: 'Points',
					icon: <StarOutlined />,
					counter: 0,
					path: '/points',
				},
				{
					permission: true,
					label: 'Fees',
					icon: <FeesLogo />,
					counter: 0,
					path: '/fees',
				},
			],
		},

		{
			groupTitle: 'Information',
			menus: [
				{
					permission: true,
					label: 'Terms & Conditions',
					icon: <FileTextOutlined />,
					counter: 0,
					path: '/terms-and-conditions',
				},
				{
					permission: true,
					label: 'Privacy Policy',
					icon: <FileOutlined />,
					counter: 0,
					path: '/privacy-policy',
				},
				{
					permission: true,
					label: 'Referral Information',
					icon: <FileOutlined />,
					counter: 0,
					path: '/referral-information',
				},
				{
					permission: true,
					label: 'FAQ',
					icon: <QuestionCircleOutlined />,
					counter: 0,
					path: '/faq',
				},
				// {
				//   permission: true,
				//   label: 'Disclaimer Regarding Time',
				//   icon: <TagOutlined />,
				//   counter: 0,
				//   path: '/disclaimer-regarding-time',
				// },
				{
					permission: true,
					label: 'Chat Customer Service',
					icon: <TagOutlined />,
					counter: 0,
					path: '/chat-customer-service',
				},
				{
					permission: true,
					key: 'balance-and-point',
					label: 'Balance and Point',
					icon: <WifiOutlined rotate={45} />,
					counter: 0,
					subMenuTitleGroup: 'Balance and Point',
					subMenu: true,
					subMenuItems: [
						{
							permission: true,
							label: 'Balance Information',
							icon: <GiftOutlined />,
							counter: 0,
							path: '/balance-information',
						},
						{
							permission: dummyUser.permissions.includes(
								EBatchPermissions.VIEW,
							),
							label: 'Point Information',
							icon: <TagOutlined />,
							counter: 0,
							path: '/point-information',
						},
					],
				},
				{
					permission: true,
					label: 'Medical Calculator',
					icon: <CalculatorOutlined />,
					counter: 0,
					path: '/medical-calculator',
				},
				{
					permission: true,
					label: 'Customer Feedback',
					icon: <MessageOutlined />,
					counter: 0,
					path: '/customer-feedbacks',
				},
			],
		},
	];

	const convertPathName = () => {
		const pathname = location.pathname.split('/');
		const res = '/' + pathname[1];
		return res;
	};

	return (
		<Menu
			id="menu-sidebar"
			mode="inline"
			onOpenChange={(e) => toggleActiveSideMenu(e)}
			openKeys={activeSubMenu}
			defaultSelectedKeys={[convertPathName()]}
		>
			{menus.map((data: any, idx: number) => (
				<AppMenuItem key={data.groupTitle} data={data} />
			))}
			<div style={{ height: 60 }} />
		</Menu>
	);
};

export default AppMenuSidebar;
