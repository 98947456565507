import {
	Button,
	Card,
	Col,
	Divider,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Typography,
} from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppCard from '../../components/AppCard';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import 'react-quill/dist/quill.snow.css';
import {
	FeesProps,
	initialFees,
	initialReferralFees,
	ReferralFeesProps,
} from '../../types/fees.type';
import { replaceUnderscoreWithSpace } from '../../helpers/replaceUnderscoreWithSpace';
import { generateQueryString } from '../../helpers/generateQueryString';
import { ReactComponent as FeesLogo } from '../../assets/icons/fees_icon.svg';
import { DeleteOutlined } from '@ant-design/icons';

const CONFIG_KEYS = [
	'CONSULT_NOW_PRICE',
	'TELEMEDICINE_EXTEND',
	'PATIENT_PWD_DISCOUNT',
	'PATIENT_TRANSACTION_FEE',
	'DOCTOR_COMMISSION_FEE',
	'DOCTOR_TRANSACTION_FEE',
	'DOCTOR_DOCTOR_DOCUMENT_FEE',
	'MEDICALSERVICE_PATIENT_TRANSACTION_FEE',
	'MEDICALSERVICE_HOME_TRANSPORT_FEE',
	'MEDICALSERVICE_LAB_COMISSION_FEE',
	'MEDICALSERVICE_LAB_TRANSACTION_FEE',
	'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR',
	'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT',
	'REWARD_POINT_REFERRAL_UPLINE_DOCTOR',
	'REWARD_POINT_REFERRAL_UPLINE_PATIENT',
];

const { Text } = Typography;

interface ILocation {}

interface ResponseProps extends BaseResponseProps {
	payload: {
		results: Array<FeesProps>;
	};
}

export interface DistanceProps {
	startAt: number;
	endAt: number;
	price: number;
}

const Fees: React.FC = () => {
	const history = useHistory();
	const location = useLocation<ILocation>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [fees, setFees] = React.useState<Array<FeesProps>>([]);
	const [selectedFee, setSelectedFee] = React.useState<FeesProps>(initialFees);
	const [selectedReferralFee, setSelectedReferralFee] =
		React.useState<ReferralFeesProps>(initialReferralFees);

	const [transportDistance, setTransportDistance] = React.useState<
		Array<DistanceProps>
	>([
		{
			startAt: 0,
			endAt: 1,
			price: 0,
		},
	]);

	const [isShowModalEdit, setIsShowModalEdit] = React.useState<boolean>(false);
	const [isShowModalEditConsultNow, setIsShowModalEditConsultNow] =
		React.useState<boolean>(false);
	const [isShowModalEditTransportFees, setIsShowModalEditTransportFees] =
		React.useState<boolean>(false);
	const [isShowModalEditReferral, setIsShowModalEditReferral] =
		React.useState<boolean>(false);

	const updateFees = async (
		props: Omit<FeesProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			setIsLoadingAction(true);

			console.log(props);
			await httpRequest.patch('/app-configs/' + props.key, props);
			message.success(
				'Success update ' + replaceUnderscoreWithSpace(props.key),
			);

			setIsLoadingAction(false);

			fetchFeesDetail();
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const updateReferral = async (props: ReferralFeesProps) => {
		try {
			setIsLoadingAction(true);
			let arrKey =
				props.type === 'DOCTOR'
					? [
							'REWARD_POINT_REFERRAL_UPLINE_DOCTOR',
							'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR',
					  ]
					: [
							'REWARD_POINT_REFERRAL_UPLINE_PATIENT',
							'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT',
					  ];

			for (const key of arrKey) {
				await httpRequest.patch('/app-configs/' + key, {
					value:
						key === `REWARD_POINT_REFERRAL_UPLINE_${props.type}`
							? props.uplinePoint
							: props.downlinePoint,
				});
			}

			message.success('Success update referral ' + props.type.toLowerCase());
			setIsLoadingAction(false);
			fetchFeesDetail();
			setSelectedReferralFee(initialReferralFees);
		} catch (error) {
			setIsLoadingAction(false);
		}
	};

	const handleClickDetail = (e: FeesProps) => {
		history.push(`/fees/${e.key}`);
	};

	const fetchFeesDetail = async () => {
		try {
			setIsLoading(true);

			const res = await httpRequest.get<ResponseProps>(
				`/app-configs${generateQueryString({ keys: CONFIG_KEYS.toString() })}`,
			);

			console.log(res.data.payload.results);

			setFees(res.data.payload.results);

			const transportData = res.data.payload.results.find(
				(item) => item.key === 'MEDICALSERVICE_HOME_TRANSPORT_FEE',
			);

			if (transportData) {
				const splitter = transportData.value.split('},');
				const transportDist = [];
				for (var i = 0; i < splitter.length; i++) {
					if (i === splitter.length - 1) {
						transportDist.push(JSON.parse(splitter[i]));
					} else {
						const stringBuilder = splitter[i] + '}';
						transportDist.push(JSON.parse(stringBuilder));
					}
				}

				setTransportDistance(transportDist);
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchFeesDetail();
	}, [location]);

	function getValueByKey(key: string) {
		const fee = fees.find((item) => item.key === key);
		if (fee) {
			return fee.value;
		} else {
			return '';
		}
	}

	function getObjectByKey(key: string) {
		const fee = fees.find((item) => item.key === key);
		if (fee) {
			return fee;
		} else {
			return initialFees;
		}
	}

	return (
		<div>
			<HeaderSection
				icon={<FeesLogo />}
				title={'Fees'}
				subtitle="Manage Your Fees"
			/>
			<AppCard loading={isLoading}>
				<h2 style={{ paddingLeft: '5px' }}>Services</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Consult Now Price
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									₱{getValueByKey('CONSULT_NOW_PRICE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										onClick={() => {
											handleClickDetail(getObjectByKey('CONSULT_NOW_PRICE'));
										}}
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEditConsultNow(true);
											setSelectedFee(getObjectByKey('CONSULT_NOW_PRICE'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Extend Telemedicine Price
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									₱{getValueByKey('TELEMEDICINE_EXTEND')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										onClick={() => {
											handleClickDetail(getObjectByKey('TELEMEDICINE_EXTEND'));
										}}
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEditConsultNow(true);
											setSelectedFee(getObjectByKey('TELEMEDICINE_EXTEND'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Divider />

				<h2 style={{ paddingLeft: '5px' }}>Fees & Charges (Doctor)</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Commission Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('DOCTOR_COMMISSION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('DOCTOR_COMMISSION_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(getObjectByKey('DOCTOR_COMMISSION_FEE'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Transaction Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('DOCTOR_TRANSACTION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('DOCTOR_TRANSACTION_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(getObjectByKey('DOCTOR_TRANSACTION_FEE'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Doctor Document Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('DOCTOR_DOCTOR_DOCUMENT_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('DOCTOR_DOCTOR_DOCUMENT_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(
												getObjectByKey('DOCTOR_DOCTOR_DOCUMENT_FEE'),
											);
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Divider />

				<h2 style={{ paddingLeft: '5px' }}>Fees & Charges (Patient)</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Transaction Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('PATIENT_TRANSACTION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('PATIENT_TRANSACTION_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(getObjectByKey('PATIENT_TRANSACTION_FEE'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Divider />

				<h2 style={{ paddingLeft: '5px' }}>Medical Services</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Patient Transaction Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('MEDICALSERVICE_PATIENT_TRANSACTION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey(
													'MEDICALSERVICE_PATIENT_TRANSACTION_FEE',
												),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(
												getObjectByKey(
													'MEDICALSERVICE_PATIENT_TRANSACTION_FEE',
												),
											);
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Doctor Transaction Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('MEDICALSERVICE_LAB_TRANSACTION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('MEDICALSERVICE_LAB_TRANSACTION_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(
												getObjectByKey('MEDICALSERVICE_LAB_TRANSACTION_FEE'),
											);
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Healthcare Provider Commission Fee
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('MEDICALSERVICE_LAB_COMISSION_FEE')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('MEDICALSERVICE_LAB_COMISSION_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(
												getObjectByKey('MEDICALSERVICE_LAB_COMISSION_FEE'),
											);
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Home Service Transport Fee
								</Text>
							}
						>
							{transportDistance.map((distance) => {
								return (
									<Row justify="space-between">
										<Col>
											<Text style={{ fontSize: '12px' }}>
												{`More than ${distance.startAt} km to ${distance.endAt} km`}
											</Text>
										</Col>
										<Col>
											<Text style={{ fontSize: '12px' }}>
												₱{distance.price}
											</Text>
										</Col>
									</Row>
								);
							})}
							<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '10%' }}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(
												getObjectByKey('MEDICALSERVICE_HOME_TRANSPORT_FEE'),
											);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEditTransportFees(true);
											setSelectedFee(
												getObjectByKey('MEDICALSERVICE_HOME_TRANSPORT_FEE'),
											);
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Divider />

				<h2 style={{ paddingLeft: '5px' }}>Discount</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Patient PWD/Senior Discount
								</Text>
							}
						>
							<Row style={{ paddingBottom: '10%' }}>
								<Text strong style={{ fontSize: '16px' }}>
									{getValueByKey('PATIENT_PWD_DISCOUNT')}
								</Text>
							</Row>
							<Row justify="end" gutter={[8, 8]}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											handleClickDetail(getObjectByKey('PATIENT_PWD_DISCOUNT'));
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEdit(true);
											setSelectedFee(getObjectByKey('PATIENT_PWD_DISCOUNT'));
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>

				<Divider />

				<h2 style={{ paddingLeft: '5px' }}>Referral</h2>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Doctor Referral
								</Text>
							}
						>
							<Row justify="space-between">
								<Col>
									<Text style={{ fontSize: '12px' }}>Upline Point</Text>
								</Col>
								<Col>
									<Text style={{ fontSize: '12px' }}>
										{getValueByKey('REWARD_POINT_REFERRAL_UPLINE_DOCTOR')}
									</Text>
								</Col>
							</Row>
							<Row justify="space-between">
								<Col>
									<Text style={{ fontSize: '12px' }}>Downline Point</Text>
								</Col>
								<Col>
									<Text style={{ fontSize: '12px' }}>
										{getValueByKey('REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR')}
									</Text>
								</Col>
							</Row>
							<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '10%' }}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											history.push(`/fees/REFERRAL_POINT_DOCTOR`);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEditReferral(true);
											setSelectedReferralFee({
												uplinePoint: getValueByKey(
													'REWARD_POINT_REFERRAL_UPLINE_DOCTOR',
												),
												downlinePoint: getValueByKey(
													'REWARD_POINT_REFERRAL_DOWNLINE_DOCTOR',
												),
												type: 'DOCTOR',
											});
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col span={8}>
						<Card
							title={
								<Text strong style={{ fontSize: '14px' }}>
									Patient Referral
								</Text>
							}
						>
							<Row justify="space-between">
								<Col>
									<Text style={{ fontSize: '12px' }}>Upline Point</Text>
								</Col>
								<Col>
									<Text style={{ fontSize: '12px' }}>
										{getValueByKey('REWARD_POINT_REFERRAL_UPLINE_PATIENT')}
									</Text>
								</Col>
							</Row>
							<Row justify="space-between">
								<Col>
									<Text style={{ fontSize: '12px' }}>Downline Point</Text>
								</Col>
								<Col>
									<Text style={{ fontSize: '12px' }}>
										{getValueByKey('REWARD_POINT_REFERRAL_DOWNLINE_PATIENT')}
									</Text>
								</Col>
							</Row>
							<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '10%' }}>
								<Col>
									<Button
										type="text"
										style={{
											width: '100px',
											height: '36px',
											color: '#D81F64',
										}}
										onClick={() => {
											history.push(`/fees/REFERRAL_POINT_PATIENT`);
										}}
									>
										View Detail
									</Button>
								</Col>
								<Col>
									<Button
										onClick={() => {
											setIsShowModalEditReferral(true);
											setSelectedReferralFee({
												uplinePoint: getValueByKey(
													'REWARD_POINT_REFERRAL_UPLINE_PATIENT',
												),
												downlinePoint: getValueByKey(
													'REWARD_POINT_REFERRAL_DOWNLINE_PATIENT',
												),
												type: 'PATIENT',
											});
										}}
										style={{
											width: '100px',
											height: '36px',
											borderColor: '#D81F64',
											color: '#D81F64',
										}}
									>
										Edit
									</Button>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</AppCard>

			{/* ----- modal consult now ----- */}
			<Modal
				title="Consult Now Price"
				visible={isShowModalEditConsultNow}
				footer={null}
				onCancel={() => {
					setIsShowModalEditConsultNow(false);
					setSelectedFee(initialFees);
				}}
				confirmLoading={isLoadingUpdateStatus}
			>
				<Text
					style={{
						fontWeight: '400px',
						fontSize: '12px',
						lineHeight: '150%',
						color: '#556575',
					}}
				>
					Consult Now
				</Text>
				<Row>
					<Input
						prefix="₱"
						onChange={(e) => {
							setSelectedFee({
								...selectedFee,
								value: e.target.value,
							});
						}}
						defaultValue={selectedFee.value}
					/>
				</Row>
				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditConsultNow(false);
								setSelectedFee(initialFees);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateFees(selectedFee);
								setIsShowModalEditConsultNow(false);
								setSelectedFee(initialFees);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal edit ----- */}
			<Modal
				title={replaceUnderscoreWithSpace(selectedFee.key)}
				visible={isShowModalEdit}
				footer={null}
				onCancel={() => {
					setIsShowModalEdit(false);
					setSelectedFee(initialFees);
				}}
				confirmLoading={isLoadingUpdateStatus}
			>
				<Text
					style={{
						fontWeight: '400px',
						fontSize: '12px',
						lineHeight: '150%',
						color: '#556575',
					}}
				>
					Fee Nominal
				</Text>
				<Row>
					<Input
						onChange={(e) => {
							setSelectedFee({
								...selectedFee,
								value: e.target.value,
							});
						}}
						value={selectedFee.value}
					/>
				</Row>
				<Row>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '10px',
							lineHeight: '150%',
							color: '#556575',
						}}
					>
						{`eg. 10%+50 (10% + 50 peso)`}
					</Text>
				</Row>
				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEdit(false);
								setSelectedFee(initialFees);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateFees(selectedFee);
								setIsShowModalEdit(false);
								setSelectedFee(initialFees);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal transport fees now ----- */}
			<Modal
				title={'Home Service Transport Fees'}
				visible={isShowModalEditTransportFees}
				footer={null}
				onCancel={() => {
					setIsShowModalEditTransportFees(false);
					setSelectedFee(initialFees);
				}}
				confirmLoading={isLoadingUpdateStatus}
			>
				<Row justify="space-between" gutter={[8, 8]}>
					<Col span={16}>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Distance
						</Text>
					</Col>
					<Col span={5}>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Transport Fees
						</Text>
					</Col>
					<Col>
						<Text
							style={{
								fontWeight: '400px',
								fontSize: '12px',
								lineHeight: '150%',
								color: '#556575',
							}}
						>
							Action
						</Text>
					</Col>
				</Row>
				{transportDistance.map((distance, index) => {
					return (
						<Row justify="space-between" style={{ paddingTop: '2%' }}>
							<Col span={8}>
								<Text
									style={{ fontSize: '12px' }}
								>{`More Than ${distance.startAt} KM to`}</Text>
							</Col>

							<Col offset={1}>
								<InputNumber
									min={distance.startAt + 1}
									style={{
										width: '150px',
										height: '36px',
									}}
									disabled={index !== transportDistance.length - 1}
									onChange={(e) => {
										const newDistance = transportDistance;
										newDistance[index].endAt = Number(e);
										setTransportDistance(newDistance);
									}}
									defaultValue={distance.endAt}
								/>
							</Col>

							<Col span={5}>
								<InputNumber
									prefix="₱"
									style={{
										width: '90px',
										height: '36px',
									}}
									onChange={(e) => {
										const newDistance = transportDistance;
										newDistance[index].price = Number(e);
										setTransportDistance(newDistance);
									}}
									defaultValue={distance.price}
								/>
							</Col>

							<Col>
								<Button
									icon={<DeleteOutlined style={{ color: '#D81F64' }} />}
									disabled={transportDistance.length - 1 !== index}
									style={{
										marginLeft: '5px',
									}}
									onClick={() => {
										const newDistance = transportDistance.splice(-1);
										setTransportDistance(newDistance);
									}}
								/>
							</Col>
						</Row>
					);
				})}

				<Row>
					<Col span={24}>
						<Button
							type="primary"
							block
							style={{
								margin: '1rem 0 20px 0',
								borderColor: '#D81F64',
								backgroundColor: 'white',
								color: '#D81F64',
							}}
							onClick={function () {
								setTransportDistance((current) => [
									...current,
									{
										startAt:
											transportDistance[transportDistance.length - 1].endAt,
										endAt:
											transportDistance[transportDistance.length - 1].endAt + 1,
										price: 0,
									},
								]);
							}}
						>
							Add New Distance
						</Button>
					</Col>
				</Row>

				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditTransportFees(false);
								setSelectedFee(initialFees);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								const transportdistanceString = transportDistance.map(
									(distance) => JSON.stringify(distance),
								);

								const transportfees = {
									...selectedFee,
									value: transportdistanceString.toString(),
								};

								updateFees(transportfees);
								setIsShowModalEditTransportFees(false);
								setSelectedFee(initialFees);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>

			{/* ----- modal referral ----- */}
			<Modal
				title={`Referral ${
					selectedReferralFee.type === 'DOCTOR' ? 'Doctor' : 'Patient'
				}`}
				visible={isShowModalEditReferral}
				footer={null}
				onCancel={() => {
					setIsShowModalEditReferral(false);
					setSelectedReferralFee(initialReferralFees);
				}}
				confirmLoading={isLoadingUpdateStatus}
			>
				<div>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '12px',
							lineHeight: '150%',
							color: '#556575',
						}}
					>
						Upline Point
					</Text>
					<Row>
						<Input
							onChange={(e) => {
								setSelectedReferralFee({
									...selectedReferralFee,
									uplinePoint: e.target.value,
								});
							}}
							value={selectedReferralFee.uplinePoint}
						/>{' '}
					</Row>
				</div>

				<div style={{ marginTop: 10 }}>
					<Text
						style={{
							fontWeight: '400px',
							fontSize: '12px',
							lineHeight: '150%',
							color: '#556575',
							marginTop: 12,
						}}
					>
						Downline Point
					</Text>
					<Row>
						<Input
							onChange={(e) => {
								setSelectedReferralFee({
									...selectedReferralFee,
									downlinePoint: e.target.value,
								});
							}}
							value={selectedReferralFee.downlinePoint}
						/>
					</Row>
				</div>

				<Row justify="end" gutter={[8, 8]} style={{ paddingTop: '5%' }}>
					<Col>
						<Button
							onClick={() => {
								setIsShowModalEditReferral(false);
								setSelectedReferralFee(initialReferralFees);
							}}
						>
							Cancel
						</Button>
					</Col>
					<Col>
						<Button
							onClick={() => {
								updateReferral(selectedReferralFee);
								setIsShowModalEditReferral(false);
								setSelectedReferralFee(initialReferralFees);
							}}
							style={{
								backgroundColor: '#D81F64',
								color: 'white',
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Modal>
		</div>
	);
};

export default Fees;
